<template>
  <footer>

    <!-- footer -->
    <section v-show="!pageIsLoading" class="footer-section">
      <div v-if="footerData.instagram">
        <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
          {{alertText}}
        </b-alert>
      
        <div v-if="currentPage != 'contacts'" style="text-align:center">
          <div class="container">
            <b-row class="mt-5">
              <b-col lg="6" md="12" sm="12" class="d-flex justify-content-center align-items-center">
                <div class=" footer-col footer-btn-group mr-5 ml-5 mt-2 mb-4" >
                  <img class="nav-logo mt-1" style="width: 150px;" :src="footerData.instagram" alt="logo" />
                  <br>
                  <p class="footer-data">
                    <span><a :href="'tel:' + footerData.phone">{{ footerData.phone }}</a></span>
                    <br>
                    <span><a :href="'mailto:' + footerData.email">{{ footerData.email }}</a></span>
                    <br>
                    {{ footerData.address }}
                    <br>
                    <b-button var class="mx-2 mr-2" @click="openNewTab(footerData.location)"><i class="fa fa-map-marker ml-0 mr-2"></i>{{ langDec['location'] }}</b-button>
                  </p>
                </div>
              </b-col>
              <b-col lg="6" md="12" sm="12">
                <div class="footer-col footer-form mt-2 mb-5">

                      <b-form @submit.stop.prevent="onSubmit">
                        <div class="form-div">
                          <b-row>

                            <!-- name -->
                            <b-col lg="6" md="12">
                              <b-form-group id="group-admin-edit-name" :label="langDec['name'] + ' *'" label-for="admin-edit-name">
                                <b-form-input
                                  id="admin-edit-name"
                                  name="admin-edit-name"
                                  v-model="form.name"
                                  aria-describedby="input-edit-name"
                                ></b-form-input>
                              </b-form-group>
                            </b-col>


                            <!-- company -->
                            <b-col lg="6" md="12">
                              <b-form-group id="group-admin-edit-company" :label="langDec['company']" label-for="admin-edit-company">
                                <b-form-input
                                  id="admin-edit-company"
                                  name="admin-edit-company"
                                  v-model="form.company"
                                  aria-describedby="input-edit-company"
                                ></b-form-input>
                              </b-form-group>
                            </b-col>


                            <!-- phone -->
                            <b-col lg="6" md="12">
                              <b-form-group id="group-admin-edit-phone" :label="langDec['phone'] + ' *'" label-for="admin-edit-phone">
                                <b-form-input
                                  type="tel"
                                  id="admin-edit-phone"
                                  name="admin-edit-phone"
                                  v-model="form.phone"
                                  aria-describedby="input-edit-phone"
                                ></b-form-input>
                              </b-form-group>
                            </b-col>


                            <!-- email -->
                            <b-col lg="6" md="12">
                              <b-form-group id="group-admin-edit-email" :label="langDec['email']" label-for="admin-edit-email">
                                <b-form-input
                                  type="email"
                                  id="admin-edit-email"
                                  name="admin-edit-email"
                                  v-model="form.email"
                                  aria-describedby="input-edit-email"
                                ></b-form-input>
                              </b-form-group>
                            </b-col>


                            <!-- message -->
                            <b-col lg="12" md="12">
                              <b-form-group id="group-admin-edit-message" :label="langDec['message']" label-for="admin-edit-message">
                                <b-form-textarea
                                  rows="4"
                                  id="admin-edit-message"
                                  name="admin-edit-message"
                                  v-model="form.message"
                                  aria-describedby="input-edit-message"
                                ></b-form-textarea>
                              </b-form-group>
                            </b-col>

                            <b-col lg="12" md="12">
                              <b-button type="submit" variant="primary">{{ langDec['send'] }}</b-button>
                            </b-col>

                          </b-row>
                        </div>
                      </b-form>


                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </section>
    <span style="display:none;text-align:center;">
      <h1>
        EGYPAL FRANCE - EgyPal is considered a leading company in the field of selling , buying and recycling very high quality pallets in France and Europe.
Integrity and Innovation : We're dedicated to integrity in all we do, driving innovation to serve our customers better.
      </h1>
    </span>
  </footer>
</template>

<script>
import store from "../../store/index.js"
import { sendRequest }  from "../../utils/RequestHelper.js"

export default {
  data() {
    return {
      langDec: {},
      langIsFr: null,
      footerData: {},
      
      alertCountdown: 0,
      alertType: '',
      alertText: '',

      // form
      form: {
        name: null,
        company: null,
        phone: null,
        email: null,
        message: null,
      },
      currentPage: null,
    };
  },
  watch: {
    $route(to, from) {
      this.username = this.$store.getters.username;
      this.userImage = this.$store.getters.getImage;
    }
  },
  methods: {
    countDownChanged(alertCountdown) {
      this.alertCountdown = alertCountdown
    },
    openNewTab(link){
      window.open(link, '_blank').focus();
    },
    onSubmit() {
        if(this.form.name && this.form.phone){
          this.createItem();
        }
        else{
          this.alertType = "danger"
          this.alertText = 'Required data'
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        }
      },
      resetForm() {
        this.form = {
          name: null,
          company: null,
          phone: null,
          email: null,
          message: null,
        }
      },


      // get, create and edit
      createItem() {
        this.pageIsLoading = true;
        this.form.product_id = null;
        this.form.type = 'other'
        var successCallback = () => {
          this.pageIsLoading = false;
          this.alertType = "success"
          this.alertText = this.langDec['sendMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.resetForm();
        };
        var errorCallback = (error) => {

          let errors = error.response.data;
          let errorMessage = ''
          for (const prop in errors) {
            if (Object.hasOwn(errors, prop)) {
              errorMessage = errors[prop] ;
              this.alertText = errorMessage
              this.alertType = "danger"
              this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            }
          }
          this.pageIsLoading = false;
          
        };
        sendRequest('post', 'orders', true, this.form, successCallback, errorCallback);

      },
    setIP(){

      let pass = prompt("Enter password please");
      if(pass == 606){
        let apiUrl = null
        if(!store.getters.getAPIURL) {
          const protocol = window.location.protocol; // "http:" or "https:"
          const hostname = window.location.hostname; // domain name
          apiUrl = protocol + "//api." + hostname + '/'
          apiUrl += 'api/'
        }
        else{
          apiUrl = store.getters.getAPIURL
        }
        let ip = prompt("Please enter your IP !", apiUrl);

        if (ip.includes('http')) {
          if(ip[ip.length-1] != '/'){
            ip += '/'
          }
          if(!ip.includes('api')){
            ip += 'api/'
          }

          store.commit("SET_APIURL", ip);
          location.reload()
        }
        else{
          alert('No http or https in URL')
        }
      }
    },
    getData(){
      this.pageIsLoading = true;
      var successCallback = (response) => {
        this.pageIsLoading = false;
        let data = response.data.data
        this.footerData = {

          // logo
          instagram: data.instagram,

          // locations and sites
          phone: data.phone,                //phone 1
          email: data.email,
          address: data.address,            //address 1
          location: data.location,          //location 1
        }
      };
      var errorCallback = (error) => {
        if(error.response.status == 404) {
          // this.$router.push({
          //   name: 'home'
          // })
        }
        this.pageIsLoading = false;
        this.alertType = "danger"
        this.alertText = this.langDec['errorMessage']
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
      };
      sendRequest('get', 'dashboard/configrations/1', true, null, successCallback, errorCallback);
    }
  },
  created() {
    this.currentPage = this.$route.name
    this.username = this.$store.getters.username;
    this.userImage = this.$store.getters.getImage;
    this.langDec = store.getters.getLangData
    this.langIsFr = store.getters.getlang == 'fr'
    this.getData();
    if(!store.getters.getAPIURL){
      const protocol = window.location.protocol; // "http:" or "https:"
      const hostname = window.location.hostname; // domain name
      let apiUrl = protocol + "//" + 'api.' + hostname + '/api/'
      store.commit("SET_APIURL", apiUrl);
    } 
    
  }
};
</script>

<style scoped>

.footer-section{
  color: #882627 !important;
}
.footer-info{
  background-color: rgb(14, 14, 14);
  color: rgb(228, 228, 228);
}
.footer-data{
  font-size: 22px;
  padding-top: 20px;
}
.footer-data button{
  color: #882627;
  margin-top: 10px;
  background-color: #b19973 !important;
  border: 3px solid white;
  border-radius: 10px;
}
.footer-form{
  text-align: left;
}
.footer-col.footer-form button{
  display: block;
  visibility: visible;
}
.footer-dark{
  padding-top: 15px;
  text-align: center;
}
.footer-info .footer-col button{
  display: none;
  visibility: hidden;
}

@media (max-width: 800px) {
  .nav-item-link {
    text-align: center;
    display: block;
  }
  .nav-item-link {
    margin-right: 0 !important;
  }
}
</style>