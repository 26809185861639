export default {

  // form and basics
  login: 'Login',
  add: 'Add',
  save: 'Save',
  done: 'Done',
  edit: 'Edit',
  delete: 'Delete',
  cancel: 'Cancel',
  back: 'Back',
  name: 'Name',
  username: 'Username',
  notfound: 'Not Found',
  requests: 'Requests',
  email: 'Email',
  company: 'Company',
  message: 'Message',
  ourValue: 'Our Value',
  more: 'More',
  send: 'Send',
  location: 'Location',
  whatWeOffer: 'What we offer',
  phone: 'Phone',
  password: 'Password',
  oldPassword: 'Old password',
  newPassword: 'New password',
  accountStatus: 'Account status',
  search: 'Search',
  choose: 'Choose...',
  active: 'Active',
  disactive: 'Disactive',
  allRightsRecieved: 'All Rights Recieved',

  // success messages
  savedMessage: 'Saved successfully',
  sendMessage: 'Your request has been sent successfully',

  // error messages
  errorMessage: 'Error',

  // required messages
  nameRequired: 'Name is required',
  usernameRequired: 'Username is required',
  emailRequired: 'Email is required',
  emailValidRequired: 'Email is not valid',
  phoneRequired: 'Phone is required',
  phoneLengthRequired: 'Phone is 11 number',
  passwordRequired: 'Password is required',
  passwordLengthRequired: "Password more than 8 character",
  accountStatusRequired: 'Account status is required',
  userOrPasswordWrong: 'Wrong username or password',

  // side nav
  homepage: 'Homepage',
  settings: 'Settings',
  
  // products
  products: 'Products',

  // services
  services: 'Recycling & collection',

  // contacts
  contacts: 'Contacts',
  contactUs: 'Contact us',


};