<template>
  <div id="app">

    <!-- navBar -->
    <navbar></navbar>

    <router-view class="app" :class="{'pageContent': isLoginPage}"></router-view>

  </div>
</template>

<script>
import store from "./store/index.js"
import navbar from './components/topNavBar/TopNavBar.vue'

export default {
  data() {
    return {
      form: "",
      accountType: null,
      isLoginPage: true,
    };
  },

  // components
  components: {
    navbar
  },

  watch: {
    '$route' (to, from) {
      this.isLoginPage = true;
      if(this.$route.name == 'login'){
        this.isLoginPage = false;
      }
      else{
        this.isLoginPage = true;
      }
    }
  },
  created() {
    
    this.isLoginPage = true;
    if(this.$route.name == 'login' || this.$route.name == 'landingPage'){
      this.isLoginPage = false;
    }
    else{
      this.isLoginPage = true;
    }

    this.lang = store.getters.getlang
    this.langIsEng = this.lang == 'en'
    store.commit("SET_INTERNATIONALIZATION", this.lang)
  }
};
</script>
<style scoped lang="scss">
  @font-face {
    font-family: "CantoraOne-Regular";
    src: url("../src/assets/fonts/CantoraOne-Regular.ttf");
  }
  @font-face {
    font-family: "cairo-regular";
    src: url("../src/assets/fonts/Cairo-Regular.ttf");
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "CantoraOne-Regular" !important;
    background-color: #d5dae5;
    -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  button .btn-primary{
    color: #fff;
    background-color: #882627 !important;
    border-color: #882627 !important;
  }

</style>
