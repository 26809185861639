<template>
  <div>

    <div class="page-content">
      <div class="">
        <div class="">
          <div class="container">

          
            <section>

              <!-- breadcrumb -->
              <div>
                <b-breadcrumb>
                  <b-breadcrumb-item @click="goToRoute('landingPage')">
                    {{langDec['homepage']}}
                  </b-breadcrumb-item>
                  <b-breadcrumb-item active>{{ langDec['products'] }}</b-breadcrumb-item>
                </b-breadcrumb>
              </div>

              
              <!-- loading -->
              <div class="text-center text-danger loadingBox" v-show="pageIsLoading">
                <b-spinner class="align-middle"></b-spinner>
              </div>
              
              <div class=" page-container" >
                <!-- items -->
                <div class="items-bg" v-show="!pageIsLoading">
                  <b-row>
                    <b-col class="products-col" lg="3" md="6" sm="6" xs="6" v-for="item in items" :key="item.id">
                      <div class="item-item" @click="goToItem(item.id)">
                        <div>
                          <b-card
                            hover
                            tag="article"
                            class="item-item-card mb-2"
                          >
                            <b-img-lazy
                              :src="item.image"
                              :lazy-src="'../assets/images/loading.gif'"
                              :alt="item.name"
                              :img-alt="item.name"
                              img-top
                            ></b-img-lazy>
                            <b-card-body>
                              <b-card-title>{{item.name.split("-")[0]}}</b-card-title>
                              <b-card-text>
                                {{item.name.split("-")[1]}}
                              </b-card-text>
                            </b-card-body>  
                          </b-card>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>


              </div>
            </section>
          
          </div>
        </div>
      </div>

    </div>
    
    <footer-div v-show="!pageIsLoading"></footer-div>
    <dark-footer  v-show="!pageIsLoading"></dark-footer>
  </div>
</template>

<script>
  import { sendRequest }  from "../utils/RequestHelper.js"
  import store from "../store/index.js"
  import footerDiv from '../components/footer/Footer.vue'
  import darkFooter from '../components/footer/DarkFooter.vue'

  export default {
    name: 'Home',
    data() {
      return {

        // meta
        lang: null,
        langIsFr: null,

        // data
        items: [],
        pageIsLoading: false,
        langDec: {}
        
      }
    },


    // components
    components: {
      footerDiv,
      darkFooter
    },


    // methods
    methods: {

      // meta
      goToRoute(name) {
        this.$router.push({
          name
        })
      },
      goToItem(id){
        this.$router.push({
          name: 'item',
          params: {
            cat: 'products',
            type: this.langDec['products'],
            id
          }
        })
      },


      // loadData
      loadData(){
        this.pageIsLoading = true;

        let query = ""

        query += '&type=item';

        var successCallback = (response) => {
          let data = response.data.data;
          if(this.langIsFr) {
            data.forEach(i => {
              i.name = i.fr_name
              this.items.push(i)
              
            });
          }
          else{
            this.items = data;
          }
          this.pageIsLoading = false;
        };
        var errorCallback = () => {
          this.pageIsLoading = false;
          this.alertType = "danger"
          this.alertText = this.langDec['errorMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', 'products?limit=999999&page=1' + query, true, null, successCallback, errorCallback);
      },
    },
    created() {
      this.lang = store.getters.getlang
      this.langIsFr = this.lang == 'fr'
      this.langDec = store.getters.getLangData
      this.loadData();
      window.scrollTo(0, 0); 
    }
  }
</script>

<style scoped>

  .items-bg{
    background-color: rgb(245, 216, 216);
    padding: 40px;
    border-radius: 20px;
  }
  .item-item{
    margin-bottom: 30px;
    cursor: pointer;
  }
  .item-item-card{
    transition: box-shadow 0.2s;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.603);
  }
  .card-body{
    padding: 0.5rem
  }
  .item-item-card:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.404);
    animation: shake 0.5s;
  }
  .item-item img{
    width: 100%;
    object-fit: contain;
    max-height: 240px;
    height: 150px;
  }
  .item-item h4{
    margin-bottom: 0;
  }
  .item-item .card-body{
    padding: 5px;
  }
  .item-item .card-title{
    font-size: 20px;
    text-align: center;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .item-item .card-text{
    font-weight: 100;
    color: #333333;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .item-item .card-title{
    font-size: 20px;
    text-align: center;
  }
  @media (max-width: 1200px) {
    .item-item .card-title{
      font-size: 20px;
    }
  }  
  @media (max-width: 600px) {
    .products-col{
      width: 50%;
    }
    .items-bg{
      padding: 20px;
      border-radius: 10px;
    }
    .card-body{
      padding: 10px;
    }
    .item-item-card{
      min-height: 222px;
      margin-bottom: 30px;
    }
    .item-item .card-title{
      font-size: 18px;
    }
    .item-item .card-text{
      font-size: 14px;
      color: rgb(54, 54, 54);
    }
  }
  @keyframes shake {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
  }
</style>