<template>
  <div class="home">
    <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="homePage-layout">
      <h3>Home page</h3>
    </div>
 </div>
</template>

<script>

  export default {
    name: 'Home',
    data() {
      return {
        PublicRoomIsLoading: false,
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        isLoading: false,
      }
    },
    methods: {
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
    },
    created() {
    },
  }
</script>
<style scoped>
  .homePage-layout{
    padding: 30px 30px;
    background-color: #F3F5F9;
    box-shadow: inset 0px 3px 10px 1px #DFDFD4;
    width: 100%;
    float: left;
  }
  .div-hdr{
    padding: 0px 10px;
  }
  .div-hdr h5{
    display: inline;
  }
  .div-hdr span {
    color: rgb(155, 155, 155);
    font-size: 14px;
  }
  .div-hdr span, .div-hdr .btn {
    float: left;
  }
  .public-rooms-body{
    padding: 30px 10px;
  }
  .room-card{
    margin: 10px 0px;
    padding: 10px;
    border-radius: 5px;
    transition: 0.4s;
  }
  .room-card:hover{
    cursor: pointer;
    box-shadow: 0px 1px 15px #7650ff88;
    border-bottom: 5px solid #882627;
    margin: 5px 0px;
  }
  .room-card .card-body .card-title{ 
    font-size: 15px;
    font-weight: bold;
  }
  .card-img img{
    width: 100%;
    display: block;
    margin: auto;
  }
  .public-rooms-body .add-room{
    position: absolute;
    left: -11px;
    background-color: #ECECFA;
    padding: 3px 15px;
  }
  .public-rooms-body .card-text{
    color: rgb(155, 155, 155);
  }
  .spinner-border{
    color: #882627;
  }
  .no-content{
    color: #29ace4;
  }
  .no-content h4{
    padding-top: 20px;
    font-size: 30px;
    color: #29ace4;
  }
  @media (max-width: 600px) {
    .div-hdr{
      padding: 0px 10px;
    }
    .homePage-layout{
      margin-top: 50px;
    }
  } 
</style>
