import Vue from 'vue'
import VueRouter from 'vue-router'

// pages
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import LandingPage from '../views/LandingPage.vue'

import Products from '../views/Products.vue'
import Services from '../views/Services.vue'
import Item from '../views/Item.vue'
import Contacts from '../views/Contacts.vue'




import routeAuth from "../utils/RoutingHelper.js"
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home,
    // beforeEnter: routeAuth.isLoggedIn,
  },

  {
    path: '/products',
    name: 'products',
    component: Products
  },
  {
    path: '/services',
    name: 'services',
    component: Services
  },
  {
    path: '/:cat/:type/item/:id',
    name: 'item',
    component: Item
  },
  
  
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts
  },
  
  

  {
    path: '/',
    name: 'landingPage',
    component: LandingPage
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    // beforeEnter: routeAuth.isLoggedIn,
  },
]
const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
