<template>
  <div>
    
    <section>
      <div class="footer-info">
        <div class="container">
          <b-row align-v="center">
            <b-col lg="6" md="12" sm="12">



              <div class="footer-col footer-r">
                <a v-if="fburl" class="mr-4 sm-icon" :href="fburl" target="_blank">
                  <i class="fa fa-facebook display-1"></i>
                </a>
                <a v-if="twitter" class="mr-4 sm-icon" :href="twitter" target="_blank">
                  <i class="fa fa-twitter display-1"></i>
                </a>
                <a v-if="linkedin" class="mr-4 sm-icon" :href="linkedin" target="_blank">
                  <i class="fa fa-linkedin display-1"></i>
                </a>
                <a v-if="alibaba" class="mr-4 sm-icon" :href="alibaba" target="_blank">
                  <span><img src="./../../assets/images/alibabaicon.png"></span>
                </a>
              </div>
            </b-col>

            <b-col lg="6" md="12" sm="12">
              <div class="footer-col footer-dark">
                <p>
                  {{ langDec['allRightsRecieved'] }} <span v-show="false">{{' v  1.0.0'}}</span>
                  <button style="display: none;" @click="setIP()">url</button>
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import store from "../../store/index.js"
import { sendRequest }  from "../../utils/RequestHelper.js"

export default {
  data() {
    return {
      langDec: {},
      langIsFr: null,
      fburl: {},
      linkedin: {},
      twitter: {},
      alibaba: {},
      
    };
  },
  methods: {
    getData(){
      this.pageIsLoading = true;
      var successCallback = (response) => {
        this.pageIsLoading = false;
        let data = response.data.data

        this.fburl = data.en_header_keywords
        this.linkedin = data.company_profile        
        this.twitter = data.google_play_link
        this.alibaba = data.header_keywords

        console.log(this.fburl)
console.log( this.linkedin)
console.log( this.twitter)
console.log( this.alibaba)
        window.scrollTo(0, 0); 
      };
      var errorCallback = (error) => {
        this.pageIsLoading = false;
        this.alertType = "danger"
        this.alertText = this.langDec['errorMessage']
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
      };
      sendRequest('get', 'dashboard/configrations/1', true, null, successCallback, errorCallback);
    },
  },
  created() {
    this.getData();
    this.currentPage = this.$route.name
    this.username = this.$store.getters.username;
    this.userImage = this.$store.getters.getImage;
    this.langDec = this.$store.getters.getLangData
    this.langIsFr = this.$store.getters.getlang == 'fr'
    if(!store.getters.getAPIURL){
      const protocol = window.location.protocol; // "http:" or "https:"
      const hostname = window.location.hostname; // domain name
      let apiUrl = protocol + "//" + 'api.' + hostname + '/api/'
      store.commit("SET_APIURL", apiUrl);
    } 
    
  }
};
</script>

<style scoped>

.footer-section{
  color: #882627 !important;
}
.footer-info{
  background-color: rgb(0, 0, 0);
  color: rgb(228, 228, 228);
}
.footer-data{
  font-size: 22px;
  padding-top: 20px;
}
.footer-data button{
  color: #882627;
  margin-top: 10px;
  background-color: #b19973 !important;
  border: 3px solid white;
  border-radius: 10px;
}
.footer-form{
  text-align: left;
}
.footer-col.footer-form button{
  display: block;
  visibility: visible;
}
.footer-dark{
  padding-top: 15px;
  text-align: center;
}
.footer-info .footer-col button{
  display: none;
  visibility: hidden;
}
.footer-r{
  text-align: center;
}
.sm-icon i{
  padding-top: -15px;
  color: white !important;
  font-size: 20px !important;
}
.sm-icon img{
  width: 25px;
  height: 25px;
  margin-top: -5px;
}
@media (max-width: 800px) {
  .nav-item-link {
    text-align: center;
    display: block;
  }
  .nav-item-link {
    margin-right: 0 !important;
  }
}
</style>