<template>
  <div>

    <!-- loading -->
    <div class="text-center text-danger loadingBox" v-show="pageIsLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>

    <div class="landing-page-container" v-show="!pageIsLoading">

      <!-- intro -->
      <section class="egypal-section">
        <div class="intro_bg">
          <h1 style="display: none">
            EgyPal is considered a leading company in the field of selling , buying and recycling very high quality pallets in France and Europe.
            Integrity and Innovation : We're dedicated to integrity in all we do, driving innovation to serve our customers better.
          </h1>
        </div>
        <b-row>
          <b-col lg="6" md="6" sm="12">
            <div class="d-flex justify-content-center align-items-center">
              <div class="intro-p">
                <div class="intro-hdr">
                  <!-- <img width="400" height="100" class="img-fluid" src="../assets/images/logoName.webp" alt="Image"/> -->
                  <h1>EGYPAL</h1>
                </div>
                <h3><p>{{ landingpageData.title }}</p></h3>
              </div>
            </div>
          </b-col>
          <b-col lg="6" md="6" sm="12">
            <div class="d-flex justify-content-center align-items-center">
              <img width="520" height="520" v-if="landingpageData.Visual_identity_samples" :src="landingpageData.Visual_identity_samples" alt="Image" class="img-fluid" />
            </div>
          </b-col>
        </b-row>
      </section>


      <!-- carousel -->
      <section class="carousel">
        <div class="">
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="3000"
            controls
            indicators
            background="#ababab"
            img-width="1280"
            img-height="500"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide v-for="img in slides">
              <template #img>
                <img class="carouselImage" :src="img.path">
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </section>


      <!-- what we do -->
      <section class="whatWeDo" v-if="landingpageData.about_us">
        <b-row>
        
          <b-col md="6" sm="12" class="d-flex justify-content-center align-items-center">
            <img :src="landingpageData.youtube" alt="Image" class="img-fluid" />
          </b-col>

          <b-col md="6" sm="12" class="d-flex justify-content-center align-items-center">
            <div class="weDo-text">
              <h2>{{ langDec['whatWeOffer'] }}</h2>
              <p>{{ landingpageData.about_us }}</p>
            </div>
          </b-col>
        </b-row>
      </section>


      <!-- products -->
      <section class="products">

        <div fluid class="products-row-body p-4">
          <b-row>
            <b-col md="12" sm="12" xm="12">
              <h3 class="mb-5" style="text-align: center;">{{ langDec['products'] }}</h3>
            </b-col>
            <b-col class="products-col" lg="3" md="6" sm="6" xm="6" v-for="i in items" :key="i.id">
              <div class="products-col-body-item" @click="goToProduct(i.id)">
                <div>
                  <b-card
                    hover
                    tag="article"
                    class="item-item-card item-item mb-2"
                  >
                    <b-img-lazy
                      width="250"
                      height="150"
                      :src="i.image"
                      :alt="i.name"
                      :img-alt="i.name"
                      img-top
                    ></b-img-lazy>
                    <b-card-body>
                      <b-card-title>{{i.name.split("-")[0]}}</b-card-title>
                      <b-card-text>
                        {{i.name.split("-")[1]}}
                      </b-card-text>
                    </b-card-body>  
                  </b-card>
                </div>
              </div>
            </b-col>
            <b-col md="12" sm="12" xm="12">
              <div style="text-align: right;" class="more-txt mt-3">
                <b-link @click="goToRoute('products')" class="mt-5">{{ langDec['more'] }}</b-link>
              </div>
            </b-col>
          </b-row>
        </div>
      </section>
      

      <!-- value -->
      <section class="value">
        <b-row>
          <b-col>
            <div class="image-container">
              <b-img style="width: 100%;" :src="landingpageData.facebook" fluid alt="Your Image" />
              <div class="image-caption">
                <h3>{{langDec['ourValue']}}</h3>
                <p class="text-center">{{landingpageData.description}}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </section>


    </div>

    <footer-div v-show="!pageIsLoading"></footer-div>
    <dark-footer  v-show="!pageIsLoading"></dark-footer>
  </div>
</template>

<script>
  import { sendRequest } from "../utils/RequestHelper.js";
  import store from "../store/index.js";
  import footerDiv from '../components/footer/Footer.vue';
  import darkFooter from '../components/footer/DarkFooter.vue';

  export default {
    name: 'Home',
    data() {
      return {
        aboutUsShowMore: false,
        pageIsLoading: false,

        // slide
        slide: 0,
        sliding: null,
        slides: [],

        langDec: {},

        langIsFr: null,

        items: [],

        landingpageData: {},
      }
    },

      
    // components
    components: {
      footerDiv,
      darkFooter
    },


    // methods
    methods: {

      // slide
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      },
      getSliders(){
        this.pageIsLoading = true;
        var successCallback = (response) => {
          this.pageIsLoading = false;
          this.slides = response.data.data.sliders;
          let data = response.data.data.products;
          if(this.langIsFr) {
            let d = []
            data.forEach(i => {
              i.name = i.fr_name
              d.push(i)
            });
            setTimeout(() => {
              this.items = d
            }, 50);
            window.scrollTo(0, 0); 
          }
          else{
            this.items = data
            window.scrollTo(0, 0); 
          }
        };
        var errorCallback = (error) => {
          this.pageIsLoading = false;
          this.alertType = "danger"
          this.alertText = this.langDec['errorMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', 'https://api.egypal.fr/api', true, null, successCallback, errorCallback, false, true);
      },
      getData(){
        this.pageIsLoading = true;
        var successCallback = (response) => {
          this.pageIsLoading = false;
          let data = response.data.data

          if(this.langIsFr) {
            // france
            this.landingpageData = {
              // homepage
              title: data.fr_title,                // value en
              about_us: data.fr_about_us,          // what we offer en
              description: data.en_description,    // value en
            }
          }
          else{
            // english
            this.landingpageData = {
              // homepage
              title: data.title,                // value en
              about_us: data.about_us,          // what we offer en
              description: data.description,    // value en
            }
          }
          this.landingpageData['Visual_identity_samples'] = data.Visual_identity_samples
          this.landingpageData['facebook'] = data.facebook
          this.landingpageData['youtube'] = data.youtube
          window.scrollTo(0, 0); 
        };
        var errorCallback = (error) => {
          if(error.response.status == 404) {
            // this.$router.push({
            //   name: 'home'
            // })
          }
          this.pageIsLoading = false;
          this.alertType = "danger"
          this.alertText = this.langDec['errorMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', 'dashboard/configrations/1', true, null, successCallback, errorCallback);
      },


      goToLogin() {
        this.$router.push({
          name: "login"
        })
      },
      goToProduct(id){
        this.$router.push({
          name: 'item',
          params: {
            cat: 'products',
            type: this.langDec['products'],
            id
          }
        })
      },
      goToRoute(name) {
        this.$router.push({
          name
        })
      }
    },
    created() {
      this.langDec = store.getters.getLangData
      this.langIsFr = store.getters.getlang == 'fr'
      this.getData();
      this.getSliders();
      window.scrollTo(0, 0); 
    }
  }
</script>

<style scoped>
  .loadingBox{
    padding-top: 335px;
    padding-bottom: 300px;
  }
  .landing-page-container{
    overflow-x: hidden !important;
    width: 100% !important;
    margin: 0px;
    padding: 0px;
    /* background-image: url(../assets/images/introBG.webp);
    background-size: auto;
    background-position: top right;
    background-repeat: no-repeat; */
  }
  .intro_bg{
    height: 722px;
    width: 490px;
    position: absolute;
    top: -100px;
    right: 0;
    background-image: url(../assets/images/intro_bg4.webp);
    background-size: auto;
    background-position: top right;
    background-repeat: no-repeat;
    display: block;
  }
  .intro-p{
    padding: 0px 90px;
    margin-top: 150px;
  }
  .egypal-section{
    padding-top: 150px;
    height: 700px;
    color: #a02722;
    font-size: 20px;
  }
  .egypal-section img{
    width: 70%;
    margin-bottom: 20px;
  }
  .egypal-section h1{
    font-size: 5rem !important;
    letter-spacing: 10px;
  }
  .image-container {
    position: relative;
  }

  .image-caption {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    width: 100%;
    color: white;
    font-size: 2rem;
  }
  .image-caption h3{
    font-size: 3rem;
    margin-bottom: 10px;
  }
  .image-caption p {
    font-size: 1.5rem;
    padding: 10px 50px;
    margin-bottom: 0;
    margin: auto;
    width: 70%;
  }


  .products{
    margin: 40px;
  }

  .products-row-body {
    background-color: #f5d8d8;
    padding: 50px !important;
    border-radius: 20px;
  }
  .products-col-body-item{
    text-align: center;
    border-radius: 5px;
    color: black;
    cursor: pointer;
  }

  .items-bg{
    background-color: #f5d8d8;
    padding: 40px;
    border-radius: 40px;
  }
  .item-item{
    margin-bottom: 30px;
    cursor: pointer;
  }
  .item-item-card{
    transition: box-shadow 0.2s;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.603);
    min-height: 220px;
    border-radius: 10px;
  }
  .item-item-card:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.404);
    animation: shake 0.5s;
  }
  .item-item h4{
    margin-bottom: 0;
  }
  .item-item img{
    width: 100%;
    object-fit: contain;
    max-height: 240px;
    height: 150px;
  }
  .item-item .card-body{
    padding-bottom: 5px;
  }
  .item-item .card-title{
    font-size: 20px;
    text-align: center;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .item-item .card-text{
    font-weight: 100;
    color: #333333;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .more-txt {
    font-size: 20px;
  }


  .whatWeDo{
    padding: 50px 20px;
    margin-top: 60px;
  }
  .whatWeDo .weDo-text{
    width: 80%;
  }
  .whatWeDo h2{
    text-align: left;
    font-size: 3rem;
    margin-bottom: 20px;
  }

  .whatWeDo p{
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  .carousel-item .carouselImage{
    height: 500px !important;
    width: 100% !important;
  }
  @media (max-width: 1200px) {
    .landing-page-container{
      background-color: #f7f7f7;
      background-image: unset;
      background-position: center right;
    }
    .intro_bg{
      display: none;
    }
    .products-col{
      width: 50%;
    }
    .egypal-section{
      padding-top: 90px;
      height: 500px;
    }
    .card-body{
      padding: 10px;
    }
    .card-body h4{
      font-size: 20px;
    }
  }
  @media (max-width: 900px) {
    .egypal-section{
      text-align: center;
      font-size: 1.3rem;
      padding-bottom: 40px;
    }
    .intro-hdr{
      width: 100% !important;
      float: left;
      display: block;
    }
    .intro-hdr img{
      width: 40% !important;
      margin-bottom: 20px;
    }
    .intro-p{
      padding: 0px 40px;
      text-align: left;
      float: left;
      margin-top: 80px;
    }
    .egypal-section h1{
      font-size: 4rem !important;
    }
    .egypal-section{
      padding-top: 90px;
      height: 700px;
    }
    .egypal-section p{
      padding-top: 10px;
      font-size: 20px !important;
    }
    .egypal-section img{
      width: 60%;
    }
    .products{
      margin: 20px;
    }
    .item-item img{
      height: 150px;
    }
    .products-col{
      width: 50%;
    }
    .card-body{
      padding: 10px;
    }
    .item-item-card{
      min-height: 190px;
      margin-bottom: 30px;
    }
    .products-col-body-item h4{
      font-size: 15px !important;
    }
    .products-col{
      margin-bottom: 20px;
    }
    .image-caption h3{
      font-size: 25px;
      margin-bottom: 5px;
    }
    .image-caption p {
      font-size: 15px;
      padding: 10px 50px;
      width: 90%;
    }
    .whatWeDo{
      padding: 20px 10px;
      margin-top: 20px;
    }
    .whatWeDo h2{
      font-size: 25px;
      margin-bottom: 10px;
    }
    .whatWeDo p{
      font-size: 15px;
      width: 90%;
    }
    .whatWeDo img{
      width: 60%;
      margin: auto;
      text-align: center;
    }
    .whatWeDo .weDo-text{
      margin-top: 50px;
      width: 80%;
    }


    .products-row-body {
      padding: 20px !important;
    }
  }
  @media (max-width: 600px) {
    .egypal-section{
      padding-top: 60px;
      height: 750px;
    }
    .image-caption p {
      padding: 10px 10px;
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    .egypal-section{
      padding-top: 60px;
      height: 650px;
    }
  }
</style>