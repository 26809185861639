export default {

  // form and basics
  login: 'Se connecter',
  add: 'Ajouter',
  save: 'Sauvegarder',
  done: 'Fait',
  edit: 'Modifier',
  delete: 'Supprimer',
  cancel: 'Annuler',
  back: 'Dos',
  name: 'Nom',
  username: "Nom d'utilisateur",
  message: 'Message',
  requests: 'Demandes',
  company: 'Entreprise',
  ourValue: 'Notre Valeur',
  notfound: 'Pas trouvé',
  whatWeOffer: 'Ce que nous offrons',
  more: 'Voir plus',
  email: 'E-mail',
  location: 'Emplacement',
  phone: 'Téléphone',
  password: 'Mot de passe',
  send: 'Envoyer',
  oldPassword: 'Ancien mot de passe',
  newPassword: 'Nouveau mot de passe',
  accountStatus: 'Statut du compte',
  search: 'Recherche',
  choose: 'choisir...',
  active: 'Actif',
  disactive: 'Désactivé',
  allRightsRecieved: 'Tous droits reçus',

  // success messages
  savedMessage: 'Enregistré avec succès',
  sendMessage: 'Votre demande a été envoyée avec succès',

  // error messages
  errorMessage: 'Erreur',

  // required messages
  nameRequired: 'Le nom est requis',
  usernameRequired: "Nom d'utilisateur est nécessaire",
  emailRequired: "L'e-mail est requis",
  emailValidRequired: "L'email n'est pas valide",
  phoneRequired: 'Le téléphone est requis',
  phoneLengthRequired: 'Le téléphone est le numéro 11',
  passwordRequired: 'Mot de passe requis',
  passwordLengthRequired: "Mot de passe de plus de 8 caractères",
  accountStatusRequired: 'Le statut du compte est requis',
  userOrPasswordWrong: "Nom d'utilisateur ou mot de passe incorrect",

  // side nav
  homepage: "Accueil",
  settings: 'Paramètres',
  
  // products
  products: 'Des produits',

  // services
  services: 'Recyclage & collecte',

  // contacts
  contacts: 'Contacts',
  contactUs: 'Contactez-nous',


};