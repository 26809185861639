const state = {
  token: null,
  user_name: null,
  account_type: null,
  user_id: null,
  lang: "fr",
  langData: {},
  apiUrl: null
}
export default state
