<template>
  <div>

    <div class="page-content">
      <div class="">
        <div class="">
          <div class="container">

            <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
              {{alertText}}
            </b-alert>
          
            <section>

              <!-- breadcrumb -->
              <div>
                <b-breadcrumb>
                  <b-breadcrumb-item @click="goToRoute('landingPage')">
                    {{langDec['homepage']}}
                  </b-breadcrumb-item>
                  <b-breadcrumb-item @click="goToRoute($route.params.cat)">
                    {{$route.params.type}}
                  </b-breadcrumb-item>
                  <b-breadcrumb-item active>{{ item.name }}</b-breadcrumb-item>
                </b-breadcrumb>
              </div>

              
              <!-- loading -->
              <div class="text-center text-danger loadingBox" v-show="pageIsLoading">
                <b-spinner class="align-middle"></b-spinner>
              </div>
              
              <div class=" page-container" >
                <!-- item -->
                <div class="item-bg" v-show="!pageIsLoading">
                  <b-row>
                    
                    <b-col lg="7" md="6" sm="12" xs="12">
                      <h2 class="mb-4">{{ item.name }}</h2>
                      <b-row>
                        <!-- image -->
                        <b-col lg="6" md="6" sm="6" xs="12">
                          <b-img class="item-img mb-4" thumbnail fluid :src="item.image" alt="Image 1"></b-img>
                        </b-col>

                        <!-- details -->
                        <b-col lg="6" md="6" sm="6" xs="12">
                          <!-- <p>{{ item.description }}</p> -->
                          <div v-html="item.description"></div>
                        </b-col>
                      </b-row>

                    </b-col>



                    <!-- form -->
                    <b-col lg="5" md="6" sm="12" xs="12">
                              
                                <b-form @submit.stop.prevent="onSubmit"  v-show="!pageIsLoading">
                                  <div class="form-div">
                                    <b-row>

                                      <!-- name -->
                                      <b-col lg="6" md="12">
                                        <b-form-group id="group-admin-edit-name" :label="langDec['name'] + ' *'" label-for="admin-edit-name">
                                          <b-form-input
                                            autofocus
                                            id="admin-edit-name"
                                            name="admin-edit-name"
                                            v-model="form.name"
                                            aria-describedby="input-edit-name"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>


                                      <!-- company -->
                                      <b-col lg="6" md="12">
                                        <b-form-group id="group-admin-edit-company" :label="langDec['company']" label-for="admin-edit-company">
                                          <b-form-input
                                            id="admin-edit-company"
                                            name="admin-edit-company"
                                            v-model="form.company"
                                            aria-describedby="input-edit-company"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>


                                      <!-- phone -->
                                      <b-col lg="6" md="12">
                                        <b-form-group id="group-admin-edit-phone" :label="langDec['phone'] + ' *'" label-for="admin-edit-phone">
                                          <b-form-input
                                            type="tel"
                                            id="admin-edit-phone"
                                            name="admin-edit-phone"
                                            v-model="form.phone"
                                            aria-describedby="input-edit-phone"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>


                                      <!-- email -->
                                      <b-col lg="6" md="12">
                                        <b-form-group id="group-admin-edit-email" :label="langDec['email']" label-for="admin-edit-email">
                                          <b-form-input
                                            type="email"
                                            id="admin-edit-email"
                                            name="admin-edit-email"
                                            v-model="form.email"
                                            aria-describedby="input-edit-email"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>


                                      <!-- message -->
                                      <b-col lg="12" md="12">
                                        <b-form-group id="group-admin-edit-message" :label="langDec['message']" label-for="admin-edit-message">
                                          <b-form-textarea
                                            rows="4"
                                            id="admin-edit-message"
                                            name="admin-edit-message"
                                            v-model="form.message"
                                            aria-describedby="input-edit-message"
                                          ></b-form-textarea>
                                        </b-form-group>
                                      </b-col>


                                    </b-row>
                                    <b-button type="submit" variant="primary">{{ langDec['add'] }}</b-button>
                                  </div>
                                </b-form>


                    </b-col>



                  </b-row>
                </div>


              </div>
            </section>
          
          </div>
        </div>
      </div>

    </div>
    <footer-div v-show="!pageIsLoading"></footer-div>
    <dark-footer  v-show="!pageIsLoading"></dark-footer>
  </div>
</template>

<script>
  import { sendRequest }  from "../utils/RequestHelper.js"
  import store from "../store/index.js"
  import { validationMixin } from "vuelidate";
  import { required, numeric } from "vuelidate/lib/validators";
  import footerDiv from '../components/footer/Footer.vue'
  import darkFooter from '../components/footer/DarkFooter.vue'
  
  export default {
    mixins: [validationMixin],
    name: 'Home',
    data() {
      return {

        // meta
        lang: null,
        langIsFr: null,
        alertCountdown: 0,
        alertType: '',
        alertText: '',

        // data
        item: {},
        currentItem: {},
        pageIsLoading: false,
        langDec: {},


        // form
        form: {
          name: null,
          company: null,
          phone: null,
          email: null,
          message: null,
        },
        
      }
    },


    // validations
    validations: {
      form: {
        name: {
          required
        },
        phone: {
          required,
          numeric,
        },
      }
    },

    // components
    components: {
      footerDiv,
      darkFooter
    },

    // methods
    methods: {

      // meta
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      goToRoute(name) {
        this.$router.push({
          name
        })
      },


      // form
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      onSubmit() {
        if(this.form.name && this.form.phone){
          this.createItem();
        }
        else{
          this.alertType = "danger"
          this.alertText = 'Required data'
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        }
      },


      // get, create and edit
      createItem() {
        this.pageIsLoading = true;
        this.form.product_id = this.$route.params.id
        var successCallback = () => {
          this.pageIsLoading = false;
          this.alertType = "success"
          this.alertText = this.langDec['sendMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        var errorCallback = (error) => {

          let errors = error.response.data;
          let errorMessage = ''
          for (const prop in errors) {
            if (Object.hasOwn(errors, prop)) {
              errorMessage = errors[prop] ;
              this.alertText = errorMessage
              this.alertType = "danger"
              this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            }
          }
          this.pageIsLoading = false;
          
        };
        sendRequest('post', 'orders', true, this.form, successCallback, errorCallback);

      },

      // loadData
      loadData(){
        this.pageIsLoading = true;

        let query = ""

        query += '&type=service';

        var successCallback = (response) => {
          let data = response.data.data[0];
          if(this.langIsFr) {
            data.name = data.fr_name
            data.description = data.fr_description
            data.recommended_message = data.fr_recommended_message
            this.form.message = data.recommended_message
            this.item = data;
          }
          else{
            this.item = data;
            this.form.message = data.recommended_message
          }
          this.pageIsLoading = false;
        };
        var errorCallback = () => {
          this.pageIsLoading = false;
          this.alertType = "danger"
          this.alertText = this.langDec['errorMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', 'products/' + this.$route.params.id, true, null, successCallback, errorCallback);
      },
    },
    created() {
      this.lang = store.getters.getlang
      this.langIsFr = this.lang == 'fr'
      this.langDec = store.getters.getLangData
      this.loadData();
      window.scrollTo(0, 0);
    }
  }
</script>

<style>
  .container{
    padding: 0 !important;
  }
  .item-bg{
    padding: 0px;
    border-radius: 20px;
  }
  .item-item{
    margin-bottom: 30px;
    cursor: pointer;
  }
  .item-item-card{
    transition: box-shadow 0.2s;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.603);
  }
  .item-item-card:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.404);
    animation: shake 0.5s;
  }
  .item-item img{
    width: 100%;
    object-fit: contain;
    max-height: 240px;
  }
  .item-img{
    width: 100%;
    object-fit: contain;
    max-height: 240px;
    height: 150px;
  }
  .form-div{
    margin-top: 60px;
    border: 2px solid rgb(168, 168, 168);
    padding: 20px;
    border-radius: 10px;
  }
  @media (max-width: 1200px) {
    .form-div{
      margin-top: 30px;
      border: 2px solid rgb(168, 168, 168);
      padding: 10px;
      border-radius: 10px;
    }
  } 
  @keyframes shake {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
  }
</style>