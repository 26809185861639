<template>
  <div class="navBar">
    <b-navbar v-show="!pageIsLoading" :class="{ 'navbar-white': isNavbarWhite }" @scroll="handleScroll" class="navbar-div navbar-transparent fixed-top" toggleable="xl" variant="faded" type="light">
      <b-navbar-brand @click="goToRoute('landingPage')" v-show="landingpageData.instagram">
        <img class="nav-logo mt-1" height="100" width="120" :src="landingpageData.instagram" alt="logo" />
      </b-navbar-brand>
      <b-navbar-toggle style="font-size:15px" target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="nav-items labels">
              <b-nav-item class="nav-link-btn mx-3" @click="goToRoute('landingPage')"><b>{{ langDec['homepage'] }}</b></b-nav-item>
              <b-nav-item class="nav-link-btn mx-3" @click="goToRoute('products')"><b>{{ langDec['products'] }}</b></b-nav-item>
              <b-nav-item class="nav-link-btn mx-3" @click="goToRoute('services')"><b>{{langDec['services']}}</b></b-nav-item>
              <b-nav-item class="nav-link-btn mx-3" @click="goToRoute('contacts')"><b>{{langDec['contactUs']}}</b></b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="nav-r ml-auto">
            <b-nav-form right v-if="landingpageData.phone">
              <b-button @click="openNewTab('tel:' + landingpageData.phone)" class="mx-2"><i class="fa fa-phone ml-0 mr-2"></i>{{ landingpageData.phone }}</b-button>
              <b-button @click="openNewTab('mailto:' + landingpageData.email)" class="mx-2 mr-2"><i class="fa fa-envelope ml-0 mr-2"></i>{{ landingpageData.email }}</b-button>
              <b-button class="mx-2 mr-3" @click="changeLang(langIsEng ? 'fr' : 'en')">
                <i style="color: black !important; font-size: 20px;height: 10px;" class="fa fa-globe ml-0 mr-2"></i><span class="sr-only">Search</span>
                {{langIsEng ? 'FR' : 'EN'}}
              </b-button>
            </b-nav-form>
          </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import store from "../../store/index.js"
import { sendRequest }  from "../../utils/RequestHelper.js"

export default {
  data() {
    return {

      isNavbarWhite: false,

      landingpageData: {},

      // data
      currentLang: null,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    openNewTab(link){
      window.open(link, '_blank').focus();
    },
    getData(){
      this.pageIsLoading = true;
      var successCallback = (response) => {
        this.pageIsLoading = false;
        let data = response.data.data
        this.landingpageData = {

          // logo
          instagram: data.instagram,


          // locations and sites
          phone: data.phone,                //phone 1
          email: data.email,
          address: data.address,            //address 1
          location: data.location,          //location 1

          phone2: data.phone2,                //phone 1
          email2: data.fr_slide_description,
          address2: data.fr_address,            //address 1
          location2: data.linkedin,          //location 1
        }
      };
      var errorCallback = (error) => {
        this.pageIsLoading = false;
        this.alertType = "danger"
        this.alertText = this.langDec['errorMessage']
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
      };
      sendRequest('get', 'dashboard/configrations/1', true, null, successCallback, errorCallback);
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const pageHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollPercentage = (scrollTop / pageHeight) * 100;
      
      if (scrollPercentage >= 1) {
        this.isNavbarWhite = true;
      } else {
        this.isNavbarWhite = false;
      }
    },


    goToRoute(name) {
      this.$router.push({
        name,
      }).catch(err => {});
    },

    
    changeLang(lang) {
      store.commit("SET_LANG", lang);
      location.reload();
    },
  },
  created() {
    this.lang = store.getters.getlang;
    this.currentLang = this.lang;
    this.langIsEng = this.lang == 'en'
    this.langDec = store.getters.getLangData
    this.getData(); 
  }
};
</script>

<style scoped>
  .fixed-top{
    height: unset;
  }
  .navbar-white {
    background-color: #f7f7f7;
    box-shadow: 0px 10px 11px rgba(0, 0, 0, 0.247);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  #nav-collapse .labels{
    margin-top: 50px;
    letter-spacing: 0.5px;
  }
  #nav-collapse .nav-r button{
    color: #000;
    background-color: #b19973;
    border: 3px solid white;
    border-radius: 10px;
  }
  .lang-select-lg{
    color: #000 !important;
    background-color: #b19973;
    border: 3px solid white;
    border-radius: 10px;
    line-height: 18px;
    display: block;
  }
  .lang-select-sm{
    color: #000 !important;
    background-color: #b19973;
    border: 3px solid white;
    border-radius: 10px;
    line-height: 18px;
    display: none;
  }
  .nav-logo{
    width: 120px;  
  }
  .nav-link-btn{
    border-bottom: 2px solid transparent;
  }
  .nav-link-btn:hover{
    border-bottom: 2px solid #882627;
  }
  @media (max-width: 1200px) {
    .navbar-collapse{
      height: 90vh;
    }
    #nav-collapse .labels{
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .nav-logo{
      width: 90px; 
      height: 80px;
    }
    .navbar-div{
      background-color: #f7f7f7 !important;
      padding: 10px 20px !important;
    }
    .lang-select-sm{
      display: block;
    }
    .lang-select-lg{
      display: none;
    }
  }
</style>