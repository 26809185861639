<template>
  <div class="notFound mt-5 pt-5">
    <div class="mt-5 pt-5">
      <h1>404</h1>
      <p>{{langDec['notfound']}}</p>
      <b-button variant="primary" @click="goToHome()">{{langDec['homepage']}}</b-button>
    </div>
  </div>
</template>
<script>
  import store from "../store/index.js"

  export default {
    data() {
      return {
        langDec: {},
      };
    },
    methods: {
      goToHome() {
        this.$router.push({
          name: "landingPage"
        })
      }
    },
    created() {
      this.langDec = store.getters.getLangData
    }
  }
</script>
<style scoped>
.notFound{
  width: 100% !important;
  padding-top: 100px;
  text-align: center;
  color: #233245;
}
.notFound h1{
  font-size: 120px;
  font-weight: bold;
}
.notFound p{
  font-size: 25px;
  font-weight: bold;
}
</style>
