<template>
  <div>

    <!-- loading -->
    <div class="text-center text-danger loadingBox" v-show="pageIsLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>


    <!-- contacts -->
    <section class="container contacts-section " v-show="!pageIsLoading">
      <div>
        <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
          {{alertText}}
        </b-alert>
      
        <div style="padding-top: 40px;text-align:center" class="mt-5">
          <b-row class="mt-5">
            <b-col lg="6" md="12" sm="12" class="">
              <div class="contacts-col contacts-btn-group mr-5 ml-5 mt-2 mb-4" >
                <p class="contacts-data">
                  <h1 class="mb-3">
                    <img class="nav-logo mt-1" style="width: 150px;" :src="landingpageData.footer_meta" alt="logo" />
                  </h1>
                  <span><a :href="'tel:' + landingpageData.phone">{{ landingpageData.phone }}</a></span>
                  <br>
                  <span><a :href="'mailto:' + landingpageData.email">{{ landingpageData.email }}</a></span>
                  <br>
                  <p style="min-height: 70px;">
                    {{ landingpageData.address }}
                  </p>
                  <b-button var class="mx-2 mr-2" @click="openNewTab(landingpageData.location)"><i class="fa fa-map-marker ml-0 mr-2"></i>{{ langDec['location'] }}</b-button>
                </p>
              </div>
            </b-col>

            <b-col lg="6" md="12" sm="12" class="">
              <div class=" contacts-col contacts-btn-group mr-5 ml-5 mt-2 mb-4" >

                <p class="contacts-data">
                  <h1 class="mb-3">
                    <img class="nav-logo mt-1" style="width: 150px;" :src="landingpageData.behance" alt="logo" />
                  </h1>
                  <span><a :href="'tel:' + landingpageData.phone2">{{ landingpageData.phone2 }}</a></span>
                  <br>
                  <span><a :href="'mailto:' + landingpageData.email2">{{ landingpageData.email2 }}</a></span>
                  <br>
                  <p style="min-height: 70px;">
                    {{ landingpageData.address2 }}
                  </p>
                  <b-button var class="mx-2 mr-2" @click="openNewTab(landingpageData.location2)"><i class="fa fa-map-marker ml-0 mr-2"></i>{{ langDec['location'] }}</b-button>
                </p>
              </div>
            </b-col>
            <b-col lg="12" md="12" sm="12">
              <div class=" contacts-col contacts-form mr-5 ml-5 mt-2 mb-5">

                    <b-form @submit.stop.prevent="onSubmit">
                      <div class="form-div">
                        <b-row>

                          <!-- name -->
                          <b-col lg="6" md="12">
                            <b-form-group id="group-admin-edit-name" :label="langDec['name'] + ' *'" label-for="admin-edit-name">
                              <b-form-input
                                autofocus
                                id="admin-edit-name"
                                name="admin-edit-name"
                                v-model="form.name"
                                aria-describedby="input-edit-name"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>


                          <!-- company -->
                          <b-col lg="6" md="12">
                            <b-form-group id="group-admin-edit-company" :label="langDec['company']" label-for="admin-edit-company">
                              <b-form-input
                                id="admin-edit-company"
                                name="admin-edit-company"
                                v-model="form.company"
                                aria-describedby="input-edit-company"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>


                          <!-- phone -->
                          <b-col lg="6" md="12">
                            <b-form-group id="group-admin-edit-phone" :label="langDec['phone'] + ' *'" label-for="admin-edit-phone">
                              <b-form-input
                                type="tel"
                                id="admin-edit-phone"
                                name="admin-edit-phone"
                                v-model="form.phone"
                                aria-describedby="input-edit-phone"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>


                          <!-- email -->
                          <b-col lg="6" md="12">
                            <b-form-group id="group-admin-edit-email" :label="langDec['email']" label-for="admin-edit-email">
                              <b-form-input
                                type="email"
                                id="admin-edit-email"
                                name="admin-edit-email"
                                v-model="form.email"
                                aria-describedby="input-edit-email"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>


                          <!-- message -->
                          <b-col lg="12" md="12">
                            <b-form-group id="group-admin-edit-message" :label="langDec['message']" label-for="admin-edit-message">
                              <b-form-textarea
                                rows="4"
                                id="admin-edit-message"
                                name="admin-edit-message"
                                v-model="form.message"
                                aria-describedby="input-edit-message"
                              ></b-form-textarea>
                            </b-form-group>
                          </b-col>

                          <b-col lg="12" md="12">
                            <b-button type="submit" variant="primary">{{ langDec['send'] }}</b-button>
                          </b-col>

                        </b-row>
                      </div>
                    </b-form>


              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </section>
    <dark-footer  v-show="!pageIsLoading"></dark-footer>  </div>
</template>

<script>
import store from "../store/index.js"
import { sendRequest }  from "../utils/RequestHelper.js"
import darkFooter from '../components/footer/DarkFooter.vue'

export default {
  data() {
    return {
      langDec: {},
      langIsFr: null,
      landingpageData: {},
      pageIsLoading: false,
      
      alertCountdown: 0,
      alertType: '',
      alertText: '',

      // form
      form: {
        name: null,
        company: null,
        phone: null,
        email: null,
        message: null,
      },
    };
  },

  
  watch: {
    $route(to, from) {
      this.username = this.$store.getters.username;
      this.userImage = this.$store.getters.getImage;
    }
  },

  // components
  components: {
    darkFooter
  },

  methods: {
    countDownChanged(alertCountdown) {
      this.alertCountdown = alertCountdown
    },
    openNewTab(link){
      window.open(link, '_blank').focus();
    },
    onSubmit() {
        if(this.form.name && this.form.phone){
          this.createItem();
        }
        else{
          this.alertType = "danger"
          this.alertText = 'Required data'
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        }
    },
    resetForm() {
      this.form = {
        name: null,
        company: null,
        phone: null,
        email: null,
        message: null,
      }
    },


    // get, create and edit
    createItem() {
        this.pageIsLoading = true;
        this.form.product_id = null;
        this.form.type = 'other'
        var successCallback = () => {
          this.pageIsLoading = false;
          this.alertType = "success"
          this.alertText = this.langDec['sendMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.resetForm();
        };
        var errorCallback = (error) => {

          let errors = error.response.data;
          let errorMessage = ''
          for (const prop in errors) {
            if (Object.hasOwn(errors, prop)) {
              errorMessage = errors[prop] ;
              this.alertText = errorMessage
              this.alertType = "danger"
              this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            }
          }
          this.pageIsLoading = false;
          
        };
        sendRequest('post', 'orders', true, this.form, successCallback, errorCallback);

    },
    getData(){
      this.pageIsLoading = true;
      var successCallback = (response) => {
        this.pageIsLoading = false;
        let data = response.data.data
        this.landingpageData = {


          // sites logo
          footer_meta: data.footer_meta,    // site 1 logo
          behance: data.behance,            // site 2 logo

          // locations and sites
          phone: data.phone,                //phone 1
          email: data.email,
          address: data.address,            //address 1
          location: data.location,          //location 1

          phone2: data.phone2,                //phone 1
          email2: data.fr_slide_description,
          address2: data.fr_address,            //address 1
          location2: data.linkedin,          //location 1
        }
      };
      var errorCallback = (error) => {
        if(error.response.status == 404) {
          // this.$router.push({
          //   name: 'home'
          // })
        }
        this.pageIsLoading = false;
        this.alertType = "danger"
        this.alertText = this.langDec['errorMessage']
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
      };
      sendRequest('get', 'dashboard/configrations/1', true, null, successCallback, errorCallback);
    } 
  },
  created() {
    this.username = this.$store.getters.username;
    this.userImage = this.$store.getters.getImage;
    this.langDec = store.getters.getLangData
    this.langIsFr = store.getters.getlang == 'fr'
    window.scrollTo(0, 0); 
    this.getData();
    if(!store.getters.getAPIURL){
      const protocol = window.location.protocol; // "http:" or "https:"
      const hostname = window.location.hostname; // domain name
      let apiUrl = protocol + "//" + 'api.' + hostname + '/api/'
      store.commit("SET_APIURL", apiUrl);
    } 
    
  }
};
</script>

<style scoped>
.loadingBox{
    padding-top: 335px;
    padding-bottom: 300px;
  }
.contacts-section{
  padding-top: 70px;
  font-size: 22px;
  color: #882627 !important;
}
.contacts-info{
  background-color: rgb(14, 14, 14);
  color: rgb(228, 228, 228);
}
.contacts-data{
  font-size: 25px !important;
  padding-top: 20px;
}
.contacts-btn-group button{
  color: #882627;
  margin-top: 10px;
  background-color: #b19973 !important;
  border: 3px solid white;
  border-radius: 10px;
}
.contacts-form{
  text-align: left;
}
.contacts-col.contacts-form button{
  display: block;
  visibility: visible;
}
.contacts-dark{
  padding-top: 15px;
  text-align: center;
}
.contacts-info .contacts-col button{
  display: none;
  visibility: hidden;
}

@media (max-width: 800px) {
  .nav-item-link {
    text-align: center;
    display: block;
  }
  .nav-item-link {
    margin-right: 0 !important;
  }
}
</style>